import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'


export default function Login() {
    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                {/* <!-- /.login-logo --> */}
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a href="../../index2.html" className="h1"><b>Devs</b>Strem</a>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Sign in to start your session</p>

                        <form action="../../index3.html" method="post">
                            <div className="input-group mb-3">
                                <input type="email" className="form-control" placeholder="Email" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" className="form-control" placeholder="Password" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faLock} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="remember" className='mr-1' />
                                        <label htmlFor="remember">
                                            Remember Me
                                        </label>
                                    </div>
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-4">
                                    <Link to="/dashboard">
                                        <button button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                    </Link>

                                </div>
                                {/* <!-- /.col --> */}
                            </div>
                        </form>

                        <div className="social-auth-links text-center mt-2 mb-3">
                            <a href="#" className="btn btn-block btn-primary">
                                <i className="fab fa-facebook mr-2"></i> Sign in using Facebook
                            </a>
                            <a href="#" className="btn btn-block btn-danger">
                                <i className="fab fa-google-plus mr-2"></i> Sign in using Google+
                            </a>
                        </div>
                        {/* <!-- /.social-auth-links --> */}

                        <p className="mb-1">
                            <Link to="/forget_password">I forgot my password</Link>
                        </p>
                        <p className="mb-0">
                            <Link to='/register' className="text-center">Register a new membership</Link>
                        </p>
                    </div>
                    {/* <!-- /.card-body --> */}
                </div>
                {/* <!-- /.card --> */}
            </div >
        </div >

    )
}
