import React from "react";
const Login = React.lazy(() => import("./views/auth/Login"));
const Register = React.lazy(() => import("./views/auth/Register"));
const Dashboard = React.lazy(() => import("./views/Dashboard"));
const ForgetPassword = React.lazy(() => import("./views/auth/ForgetPassword"));

const routes = [
  { path: "/", name: "Login", element: Login },
  { path: "/register", name: "Register", element: Register },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/forget_password", name: "ForgetPassword", element: ForgetPassword },
  // Other routes...
];

export default routes;
