import { Route, Routes } from "react-router-dom";
import Login from "./views/auth/Login";
import ForgetPassword from "./views/auth/ForgetPassword";
import Register from "./views/auth/Register";
import RoutePath from './routes';
import { Suspense } from "react";

const loading = (
  <div
    className="d-flex align-items-center justify-content-center "
    style={{ minHeight: "100vh" }}
  >
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

function App() {
  return (
    <Suspense fallback={loading}>
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        {/* <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="/register" element={<Register />} /> */}
        {RoutePath.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          )
        })}
      </Routes>
    </Suspense>
  );
}

export default App;
